import { Box, Typography } from "@mui/material";

import { useContext } from "react";
import { ContextLangue } from "../context/Context";

function AboutUs(props) {
    const [contextLangue, setContextLangue] = useContext(ContextLangue);

    return (
        <Box
            ref={props.aboutUs}
            sx={{
                backgroundColor: "rgb(231,231,231)",
                position: "relative",
                width: "100%",
                height: "100%",
                scrollSnapAlign: "start",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                scrollSnapStop: "always",
            }}
            boxSizing="border-box"
        >
            <Box
                sx={{ backgroundColor: "rgb(255, 178, 84)", zIndex: 5 }}
                width="75%"
                height="fit-content"
                padding={5}
                color="white"
            >
                <Typography
                    variant="h2"
                    sx={{ fontWeight: "bold", fontFamily: "Verdana" }}
                    paddingBottom={2}
                    data-aos="fade-right"
                    data-aos-duration="1500"
                >
                    {contextLangue === "fr" ? "À propos de nous" : "About us"}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: "Verdana",
                        fontSize: "1.5vw",
                    }}
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-delay="250"
                >
                    {contextLangue === "fr"
                        ? "Nous sommes une équipe de développeurs et designers indépendants qui se spécialisent en la conception de sites internet de haute qualité et facile d'utilisation. Nous croyons fortement que le design d'un site internet est une composante clé du succès de votre présence en ligne et nous nous efforçons de créer des produits attrayants et fonctionnels qui répondent aux besoins et aux attentes de nos clients. Nous nous engageons à offrir un service à la clientèle hors-pair puisque c'est ensemble que nous allons créer un produit qui va vous permettre de vous démarquer."
                        : "We are a team of freelance developers and designers who specialize in designing high-quality, easy-to-use websites. We strongly believe that the design of a website is a key component for the success of your online presence, and we strive to create unique and functional products that meet the needs and expectations of our customers. We are committed to providing unparalleled customer service, since it is together that we create a product that will allow you to stand out. "}
                </Typography>
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "rgb(38,104,191)",
                    width: "12.5vw",
                    height: "30vh",
                    top: "50vh",
                    left: "23.5%",
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "rgb(38,104,191)",
                    width: "12.5vw",
                    height: "12.5vw",
                    top: "calc(80vh - 12.5vw/2)",
                    left: "23.5%",
                    borderRadius: "100%",
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "rgb(112,150,201)",
                    width: "12.5vw",
                    height: "30vh",
                    top: "50vh",
                    left: "43.75%",
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "rgb(112,150,201)",
                    width: "12.5vw",
                    height: "12.5vw",
                    top: "calc(80vh - 12.5vw/2)",
                    left: "43.75%",
                    borderRadius: "100%",
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "rgb(178,200,230)",
                    width: "12.5vw",
                    height: "30vh",
                    top: "50vh",
                    left: "65%",
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "rgb(178,200,230)",
                    width: "12.5vw",
                    height: "12.5vw",
                    top: "calc(80vh - 12.5vw/2)",
                    left: "65%",
                    borderRadius: "100%",
                }}
            />
        </Box>
    );
}

export default AboutUs;
