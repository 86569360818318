import { Box, Typography } from "@mui/material";
import classes from "./StepsLayout.module.css";

function StepsItem(props) {
  return (
    <Box
      onClick={() => props.handleDivClick(props.id)}
      sx={{
        zIndex: props.zInd,
        width: "9%",
        height: "9vw",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        bottom: props.selectedDiv === props.id ? "40vh" : props.bottomPos,
        right: props.selectedDiv === props.id ? "45%" : props.rightPos,
        transition: " all 0.5s ease-in-out",
        transform: props.selectedDiv === props.id ? "scale(5)" : null,
        backgroundColor: props.bColor,
        "&:hover": {
          transform: props.selectedDiv === props.id ? null : "scale(1.3)",
          transformOrigin: props.selectedDiv === props.id ? null : "right",
          transition:
            props.selectedDiv === props.id ? null : "0.2s ease-in-out",
          cursor: props.selectedDiv === props.id ? null : "pointer",
          zIndex: 100,
        },
      }}
      className={props.classes.stepSelector}
    >
      {props.selectedDiv === props.id ? (
        <Box width="100%">
          <Typography
            color="white"
            align="center"
            variant="h3"
            sx={{ fontSize: "9px", fontFamily: "Tahoma" }}
            marginLeft={2}
            marginRight={2}
            marginBottom={1}
          >
            {props.title}
          </Typography>
          <Typography
            color="white"
            align="justify"
            sx={{ fontSize: "0.3vw", fontFamily: "Tahoma" }}
            marginLeft={2}
            marginRight={2}
          >
            {props.text}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            position: "relative",
            width: props.id === 1 ? null : "100%",
            height: props.id === 1 ? null : "100%",
          }}
        >
          <Typography
            color="white"
            align="center"
            width="100%"
            //className={props.classes.hideTitle}
            sx={{
              position: props.id === 1 ? "relative" : "absolute",
              bottom: props.id === 1 ? null : 0,
              color: props.numColor,
              marginBottom: "0.25vh",
              fontSize: "3vh",
              fontWeight: "bold",
            }}
          >
            {props.id}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default StepsItem;
