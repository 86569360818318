import { Box } from "@mui/material";
import { useState, useContext } from "react";
import WhyUsItem from "./WhyUsItem";

import { ContextLangue } from "../context/Context";

const reasonsFr = {
    ouverture:
        "Chez WigglyWeb Designs, nous nous démarquons par notre capacité à livrer des produits de haute qualité qui sont à la fois facile d'utilisation et esthétiquement plaisants. Voici quelques raisons pourquoi vous devriez nous choisir.",
    service:
        "Nous nous engageons à fournir le meilleur service à la clientèle possible. Tout au long de notre collaboration, nous allons être disponible pour répondre à vos questions et nous vous informerons des avancées du projet. Nous croyons fortement en la force d'une bonne collaboration afin de concevoir un produit qui vous correspond.",
    flexibilite:
        "Nous comprenons que chaque projet est différent, c'est pourquoi notre équipe cherchera à créer un produit unique afin de bien représenter le message que vous voulez véhiculer. Nous serons en communication avec vous tout au long du projet afin d'adapter nos services pour correspondre à vos besoins et votre budget.",
    qualite:
        "Nous sommes engagés à vous remettre un produit de la plus haute qualité au niveau ou supérieurs aux standards de votre industrie. Nous testons notre site tout au long de sa conception afin de s'assurer que le produit que vous recevrez soit clé en main.",
    prix: "Étant des travailleurs autonomes, nos frais sont bien évidemment inférieurs à ceux des grandes compagnies de développement et de design. Vous obtenez donc un produit de qualité sans avoir à débourser une somme exagérée.",
    performance:
        "En choisissant WigglyWeb Designs vous vous assurez de recevoir un produit de haute qualité et performant qui vous représente bien et qui vous permet de maximiser votre performance en ligne et de gagner de précieux clients.",
};

const reasonsEn = {
    ouverture:
        "At WigglyWeb Designs, we stand out for our ability to deliver high quality products that are both easy to use and unique. Here are some reasons why you should choose us.",
    service:
        "We are committed to providing the best customer service possible. Throughout our collaboration, we will be available to answer your questions and we will keep you informed of our progress. We strongly believe in the power of good communication in order to design a product that suits you.",
    flexibilite:
        "We understand that each project is different, which is why our team will seek to create a unique product in order to represent the message you want to convey. We will be in communication with you throughout the project in order to adapt our services to match your needs and your budget.",
    qualite:
        "We are committed to providing you with a product of the highest quality. We test our site throughout its design to ensure that the product you receive is fully functional.",
    prix: "Being self-employed helps us charge fees lower than those of large development and design companies. So you get a quality product without having to pay an inflated sum. ",
    performance:
        "By choosing WigglyWeb Designs you ensure that you receive a high-quality, high-performance product that represents you well, allows you to maximize your online performance and win valuable customers.",
};

function WhyUs(props) {
    const [selectedDiv, setSelectedDiv] = useState(1);
    const [contextLangue, setContextLangue] = useContext(ContextLangue);

    return (
        <Box
            ref={props.whyUs}
            width="100%"
            height="100vh"
            position="relative"
            sx={{
                // overflow: "hidden",
                display: "flex",
                scrollSnapAlign: "start",
                scrollSnapStop: "always",
            }}
            boxSizing="border-box"
        >
            <WhyUsItem
                item={1}
                title={
                    contextLangue === "fr" ? "Pourquoi nous choisir" : "Why us"
                }
                text={
                    contextLangue === "fr"
                        ? reasonsFr.ouverture
                        : reasonsEn.ouverture
                }
                selectedDiv={selectedDiv}
                setSelectedDiv={setSelectedDiv}
                couleur="rgb(38,104,191)"
            />
            <WhyUsItem
                item={2}
                title={
                    contextLangue === "fr"
                        ? "Service à la clientèle"
                        : "Customer service"
                }
                text={
                    contextLangue === "fr"
                        ? reasonsFr.service
                        : reasonsEn.service
                }
                selectedDiv={selectedDiv}
                setSelectedDiv={setSelectedDiv}
                couleur="rgb(112,150,201)"
            />
            <WhyUsItem
                item={3}
                title={contextLangue === "fr" ? "Flexibilité" : "Flexibility"}
                text={
                    contextLangue === "fr"
                        ? reasonsFr.flexibilite
                        : reasonsEn.flexibilite
                }
                selectedDiv={selectedDiv}
                setSelectedDiv={setSelectedDiv}
                couleur="rgb(178,200,230)"
            />
            <WhyUsItem
                item={4}
                title={
                    contextLangue === "fr"
                        ? "Qualité et fiabilité"
                        : "Quality and reliability"
                }
                text={
                    contextLangue === "fr"
                        ? reasonsFr.qualite
                        : reasonsEn.qualite
                }
                selectedDiv={selectedDiv}
                setSelectedDiv={setSelectedDiv}
                couleur="rgb(254, 157, 38)"
            />
            <WhyUsItem
                item={5}
                title={
                    contextLangue === "fr"
                        ? "Prix compétitifs"
                        : "Competitive prices"
                }
                text={contextLangue === "fr" ? reasonsFr.prix : reasonsEn.prix}
                selectedDiv={selectedDiv}
                setSelectedDiv={setSelectedDiv}
                couleur="rgb(255, 178, 84)"
            />
            <WhyUsItem
                item={6}
                title={
                    contextLangue === "fr"
                        ? "Performance en ligne"
                        : "Online performance"
                }
                text={
                    contextLangue === "fr"
                        ? reasonsFr.performance
                        : reasonsEn.performance
                }
                selectedDiv={selectedDiv}
                setSelectedDiv={setSelectedDiv}
                couleur="rgb(255, 205, 144)"
            />
        </Box>
    );
}

export default WhyUs;
