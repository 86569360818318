import { Box, Typography, styled } from "@mui/material";
import { useState, useContext } from "react";

import { ContextLangue } from "../context/Context";
import StepsItem from "./StepsItem";
import classes from "./StepsLayout.module.css";
import Fade from "react-reveal/Fade";

const stepsTestFr = {
  Analyse:
    "Nous établissons quels sont vos besoins ainsi que vos attentes concernant le site à produire. C'est-à-dire déterminer quelles sont les composantes que vous souhaitez implémenter ainsi que le type de design.",
  Planification:
    "Nous établissons ensuite un plan afin de vous donner une idée du temps de conception de votre site. Ce plan vous permet de comprendre les étapes de conception ainsi que d'avoir une idée de la durée de notre collaboration. Lorsque le plan est mis en place, nous commençons.",
  Prototypage:
    "Nous allons concevoir un prototype de design de votre site. Ceci nous permet de vous monter ce à quoi pourrait ressembler le produit final. Nous allons travailler côte-à-côte afin de le modifier, au besoin, que celui-ci corresponde à vos attentes. Prenez notes que nous allons être en contact tout au long du projet. Il est donc toujours possible d'apporter des modifications au design.",
  Conception:
    "Suite à la mise au point du design, nous allons commencer la conception du site. Notre équipe s'occupera de concevoir l'interface client afin d'y incorporer les fonctionnalités désirées tout en s'assurant de sa bonne fonctionnalité. Nous allons rester en contact afin de répondre à vos questions et afin d'apporter, au besoin, les modifications finales au design.",
  "Mise en ligne":
    "Lorsque le produit est prêt, nous allons le lancer en ligne pour le rendre disponible au public. Nous sommes toujours disponible dans le futur afin d'ajouter des composantes à votre projet ou de modifier celles qui le composent déjà. Nous sommes aussi, bien évidemment, toujours ouvert à vos futurs projet. Merci de nous avoir fait confiance pour ce projet!",
};

const stepsTestEn = {
  Analyse:
    "We establish what your needs are as well as your expectations regarding the website. That is to say, determine which components you want to implement as well as the type of design.",
  Planification:
    "We then draw up a plan to give you an idea of ​​how long it will take to design your site. This plan allows you to understand the design stages, as well as to have an idea of ​​the duration of our collaboration. When the plan is in place, we begin prototyping.",
  Prototypage:
    "We will create a design prototype of your site. This allows us to show you what the final product will look like. We will work side by side to modify it, if necessary, so that it meets your expectations. Take note that we will be in contact throughout the project. It is therefore always possible to make changes to the design.",
  Conception:
    "Following the development of the design, we will begin creating the actual website. Our team will take care of designing the client interface in order to incorporate the desired functionalities while ensuring its proper functionality. We'll stay in touch to answer your questions and make final design changes if needed. ",
  "Mise en ligne":
    "Once the product is completed, we will launch it online to make it available to the public. We are always available in the future to add and modify components to your project. We are also, of course, always open to your future projects! ",
};

function StepsLayout(props) {
  const [selectedDiv, setSelectedDiv] = useState(null);
  const [contextLangue, setContextLangue] = useContext(ContextLangue);

  const handleDivClick = (divId) => {
    setSelectedDiv(divId);
  };

  return (
    <Box
      ref={props.steps}
      width="100%"
      height="100vh"
      position="relative"
      sx={{
        backgroundColor: "rgb(231,231,231)",
        overflow: "hidden",
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
      }}
      boxSizing="border-box"
    >
      <Box>
        {!selectedDiv && (
          <Box
            sx={{
              position: "absolute",
              bottom: "1%",
              left: "1%",
              width: "55%",
            }}
          >
            <Fade left>
              <Typography
                align="left"
                variant="h1"
                color="rgb(36,36,36)"
                sx={{
                  fontFamily: "Tahoma",
                  fontSize: "17vw",
                  fontWeight: "bold",
                }}
              >
                {contextLangue === "fr" ? "NOS ÉTAPES" : "OUR STEPS"}
              </Typography>
            </Fade>
          </Box>
        )}
      </Box>

      <StepsItem
        id={1}
        zInd={5}
        selectedDiv={selectedDiv}
        bottomPos="25vh"
        rightPos="5vh"
        title={contextLangue === "fr" ? "1- Analyse" : "1- Analysis"}
        text={
          contextLangue === "fr"
            ? stepsTestFr["Analyse"]
            : stepsTestEn["Analyse"]
        }
        handleDivClick={handleDivClick}
        bColor="rgb(137,137,137)"
        classes={classes}
        numColor="rgb(0,0,0)"
      />
      <StepsItem
        id={2}
        zInd={4}
        selectedDiv={selectedDiv}
        bottomPos="20vh"
        rightPos="5vh"
        title={contextLangue === "fr" ? "2- Planification" : "2- Planning"}
        text={
          contextLangue === "fr"
            ? stepsTestFr["Planification"]
            : stepsTestEn["Planification"]
        }
        handleDivClick={handleDivClick}
        bColor="rgb(97,97,97)"
        classes={classes}
        numColor="rgb(37,37,37)"
      />
      <StepsItem
        id={3}
        zInd={3}
        selectedDiv={selectedDiv}
        bottomPos="15vh"
        rightPos="5vh"
        title={contextLangue === "fr" ? "3- Prototypage" : "3- Prototyping"}
        text={
          contextLangue === "fr"
            ? stepsTestFr["Prototypage"]
            : stepsTestEn["Prototypage"]
        }
        handleDivClick={handleDivClick}
        bColor="rgb(70,70,70)"
        classes={classes}
        numColor="rgb(95,95,95)"
      />
      <StepsItem
        id={4}
        zInd={2}
        selectedDiv={selectedDiv}
        bottomPos="10vh"
        rightPos="5vh"
        title={contextLangue === "fr" ? "4- Conception" : "4- Conception"}
        text={
          contextLangue === "fr"
            ? stepsTestFr["Conception"]
            : stepsTestEn["Conception"]
        }
        handleDivClick={handleDivClick}
        bColor="rgb(36,36,36)"
        classes={classes}
        numColor="rgb(137,137,137)"
      />
      <StepsItem
        id={5}
        zInd={1}
        selectedDiv={selectedDiv}
        bottomPos="5vh"
        rightPos="5vh"
        title={contextLangue === "fr" ? "5- Mise en ligne" : "5- Launching"}
        text={
          contextLangue === "fr"
            ? stepsTestFr["Mise en ligne"]
            : stepsTestEn["Mise en ligne"]
        }
        handleDivClick={handleDivClick}
        bColor="rgb(0,0,0)"
        classes={classes}
        numColor="rgb(212,212,212)"
      />
    </Box>
  );
}

export default StepsLayout;
