import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";

import Logo from "../images/Logo.png";

import { ContextLangue } from "../context/Context";

function Accueil(props) {
    const [contextLangue, setContextLangue] = useContext(ContextLangue);

    return (
        <Box
            ref={props.accueil}
            boxSizing="border-box"
            sx={{
                backgroundColor: "rgb(231,231,231)",
                width: "100%",
                height: "100%",

                position: "relative",
                scrollSnapAlign: "start",
                textAlign: "center",
                scrollSnapStop: "always",
            }}
        >
            <Box
                sx={{ position: "sticky", left: 0, top: 0, zIndex: 1 }}
                data-aos="fade-down"
                data-aos-duration="1500"
                data-aos-delay="500"
            >
                <Box
                    sx={{
                        position: "absolute",
                        backgroundColor: "rgb(38,104,191)",
                        width: "12.5vw",
                        height: "60vh",
                        top: 0,
                        left: "23.5%",
                    }}
                />
                <Box
                    sx={{
                        position: "absolute",
                        backgroundColor: "rgb(38,104,191)",
                        width: "12.5vw",
                        height: "12.5vw",
                        top: "calc(60vh - 12.5vw/2)",
                        left: "23.5%",
                        borderRadius: "100%",
                    }}
                />
            </Box>
            <Box
                sx={{ position: "sticky", left: 0, top: 0, zIndex: 1 }}
                data-aos="fade-down"
                data-aos-duration="1500"
                data-aos-delay="250"
            >
                <Box
                    sx={{
                        position: "absolute",
                        backgroundColor: "rgb(112,150,201)",
                        width: "12.5vw",
                        height: "60vh",
                        top: 0,
                        left: "43.75%",
                    }}
                />
                <Box
                    sx={{
                        position: "absolute",
                        backgroundColor: "rgb(112,150,201)",
                        width: "12.5vw",
                        height: "12.5vw",
                        top: "calc(60vh - 12.5vw/2)",
                        left: "43.75%",
                        borderRadius: "100%",
                    }}
                />
            </Box>
            <Box
                sx={{ position: "sticky", left: 0, top: 0, zIndex: 1 }}
                data-aos="fade-down"
                data-aos-duration="1500"
            >
                <Box
                    sx={{
                        position: "absolute",
                        backgroundColor: "rgb(178,200,230)",
                        width: "12.5vw",
                        height: "60vh",
                        top: 0,
                        left: "65%",
                    }}
                />

                <Box
                    sx={{
                        position: "absolute",
                        backgroundColor: "rgb(178,200,230)",
                        width: "12.5vw",
                        height: "12.5vw",
                        top: "calc(60vh - 12.5vw/2)",
                        left: "65%",
                        borderRadius: "100%",
                    }}
                />
            </Box>

            <Box
                width="25vw"
                //src={Logo}
                sx={{
                    position: "absolute",
                    top: "-40px",
                    right: "-25px",
                    zIndex: 2,
                }}
            >
                <img src={Logo} width="100%" />
            </Box>

            <Box
                sx={{
                    zIndex: 2,
                    position: "absolute",
                    height: "100%",
                    width: "100%",

                    left: 0,

                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Typography
                    align="center"
                    variant="h1"
                    component="h1"
                    sx={{
                        fontFamily: "Tahoma",
                        fontWeight: "bold",
                        fontSize: "5vw",
                    }}
                    data-aos="fade-right"
                    data-aos-duration="1500"
                >
                    {contextLangue === "fr"
                        ? "CRÉONS UN SITE QUI RAPPORTE."
                        : "EARN MORE WITH YOUR WEBSITE."}
                </Typography>
                <Typography
                    align="center"
                    variant="h6"
                    sx={{
                        fontFamily: "Tahoma",
                        fontWeight: "bold",
                    }}
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-delay="250"
                >
                    {contextLangue === "fr"
                        ? "NOUS CRÉONS DES DESIGNS UNIQUES POUR FAIRE GRANDIR VOTRE ENTREPRISE"
                        : "WE CREATE UNIQUE DESIGNS TO GROW YOUR BUSINESS"}
                </Typography>
                <Button
                    size="large"
                    sx={{
                        marginTop: 2,
                        width: "fit-content",
                        backgroundColor: "rgb(11,217,0)",
                        "&:hover": { backgroundColor: "rgb(5,155,0)" },
                    }}
                    variant="contained"
                    onClick={() => {
                        props.scrollToSection(props.contact);
                    }}
                >
                    <Typography
                        align="center"
                        variant="h6"
                        sx={{
                            fontWeight: "bold",
                            fontFamily: "Tahoma",
                        }}
                    >
                        {contextLangue === "fr"
                            ? "CONTACTEZ-NOUS"
                            : "CONTACT US"}
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
}

export default Accueil;
