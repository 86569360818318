import { Box, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

function WhyUsItem(props) {
  return (
    <Box
      height="100vh"
      sx={{
        transition: " all 0.5s ease-in-out",
        flex: props.selectedDiv === props.item ? 20 : 1,
        backgroundColor: props.couleur,
        position: "relative",
        "&:hover": {
          cursor: props.selectedDiv === props.item ? null : "pointer",
        },
      }}
      onClick={() => {
        props.setSelectedDiv(props.item);
      }}
    >
      {props.selectedDiv !== props.item && (
        <Typography
          width="fit-content"
          align="center"
          sx={{
            position: "absolute",
            bottom: 10,
            left: 0,
            right: 0,
            marginRight: "auto",
            marginLeft: "auto",

            textOrientation: "mixed",
            writingMode: "vertical-lr",
            fontFamily: "Tahoma",
            fontWeight: "bold",
            fontSize: "2vw",
          }}
          color="white"
        >
          {props.title}
        </Typography>
      )}
      {props.selectedDiv === props.item && (
        <Box
          sx={{
            height: "100vh",
            position: "relative",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            color="white"
            variant="h2"
            align="justify"
            width="70%"
            marginBottom={2}
            sx={{ fontWeight: "bold", fontFamily: "Tahoma", fontSize: "5vw" }}
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay="300"
          >
            {props.title}
          </Typography>
          <Typography
            color="white"
            variant="h4"
            width="70%"
            sx={{ fontSize: "3vw", fontFamily: "Tahoma" }}
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay="750"
          >
            {props.text}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default WhyUsItem;
