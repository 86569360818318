import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import emailjs from "@emailjs/browser";
import { useRef, useState, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "react-reveal/Fade";
import { ContextLangue } from "../context/Context";

function ContactSmall(props) {
  const [contextLangue, setContextLangue] = useContext(ContextLangue);
  const form = useRef();
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSentAlert, setShowSentAlert] = useState(false);

  const handleNameChange = (event) => {
    setNameValue(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessageValue(event.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (nameValue && emailValue && messageValue) {
      setShowErrorAlert(false);
      setShowSentAlert(true);

      emailjs
        .sendForm(
          `${process.env.REACT_APP_SERVICE_ID}`,
          `${process.env.REACT_APP_TEMPLATE_ID}`,
          form.current,

          `${process.env.REACT_APP_PUBLIC_KEY}`
        )
        .then(
          (result) => {
            console.log(result.text);

            setNameValue("");
            setEmailValue("");
            setMessageValue("");
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setShowSentAlert(false);
      setShowErrorAlert(true);
    }
  };

  return (
    <Box
      ref={props.contact}
      height="100%"
      sx={{
        backgroundColor: "rgb(50,50,50)",
        position: "relative",
        scrollSnapAlign: "end",
      }}
      boxSizing="border-box"
    >
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "calc(12.5% - 5px)",
          maxWidth: "75%",
        }}
      >
        <Fade left>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              fontFamily: "Verdana",
              fontSize: "14vw",
              background:
                "linear-gradient(to right, rgb(38,104,191), rgb(178,200,230))",
              backgroundClip: "text",
              color: "transparent",
              display: "flex",
            }}
          >
            {contextLangue === "fr" ? "ÉCRIVEZ-NOUS" : "WRITE TO US"}!
          </Typography>
        </Fade>
      </Box>

      <Box
        sx={{
          zIndex: 25,
          width: "75%",
          height: "50%",
          position: "absolute",
          top: "30%",
          left: "50%",
          transform: " translate(-50%, 0%)",
          border: "solid 5px orange",
        }}
      >
        <form
          ref={form}
          onSubmit={sendEmail}
          id="form"
          style={{ height: "calc(100% - 2rem)" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              justifyContent: "space-around",
              height: "100%",
              margin: 2,
            }}
          >
            <TextField
              id="name"
              type="text"
              label={contextLangue === "fr" ? "Nom" : "Name"}
              name="user_name"
              value={nameValue}
              onChange={handleNameChange}
              autoComplete="off"
              sx={{
                "& .MuiInputBase-root": {
                  color: "white",
                },
              }}
            />
            <TextField
              id="email"
              type="email"
              label="Email"
              name="user_email"
              value={emailValue}
              onChange={handleEmailChange}
              sx={{
                "& :-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 100px rgb(50,50,50) inset",
                  WebkitTextFillColor: "white",
                },
                "& .MuiInputBase-root": {
                  color: "white",
                },
              }}
            />
            <TextField
              id="message"
              type="text"
              label="Message"
              multiline
              maxRows={4}
              minRows={2}
              name="message"
              value={messageValue}
              onChange={handleMessageChange}
              autoComplete="off"
              sx={{
                "& .MuiInputBase-root": {
                  color: "white",
                },
              }}
            />
            <Button type="submit" variant="contained">
              {contextLangue === "fr" ? "Envoyer" : "Send"}
            </Button>
          </Box>
        </form>
      </Box>
      <Alert
        severity="error"
        sx={{
          position: "absolute",
          top: 15,
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          width: "fit-content",
          zIndex: 1000,

          display: showErrorAlert ? "flex" : "none",
        }}
        action={
          <Button
            color="inherit"
            size="fit-content"
            onClick={() => {
              setShowErrorAlert(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </Button>
        }
      >
        {contextLangue === "fr"
          ? "Veuillez remplir chaque champs correctement avant d'envoyer votre message."
          : "Please fill out every field correctly before sending your message."}
      </Alert>
      <Alert
        severity="success"
        sx={{
          position: "absolute",
          top: 15,
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          width: "fit-content",
          display: showSentAlert ? "flex" : "none",
          zIndex: 1000,
        }}
        action={
          <Button
            color="inherit"
            size="fit-content"
            onClick={() => {
              setShowSentAlert(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </Button>
        }
      >
        {contextLangue === "fr"
          ? "Votre message a bien été envoyé!"
          : "Your message was sent successfully"}
      </Alert>
    </Box>
  );
}

export default ContactSmall;
