import { Box } from "@mui/material";
import { useRef } from "react";
import AboutUsSmall from "../aboutUs/AboutUsSmall";
import AccueilSmall from "../accueil/AccueilSmall";
import ContactSmall from "../contact/ContactSmall";
import StepsLayoutSmall from "../steps/StepsLayoutSmall";
import WhyUsSmall from "../whyUs/WhyUsSmall";
import AOS from "aos";
import "aos/dist/aos.css";

import NavToolSmall from "../navigation/NavToolSmall";

function HomePageSmall() {
    const accueil = useRef(null);
    const aboutUs = useRef(null);
    const steps = useRef(null);
    const whyUs = useRef(null);
    const contact = useRef(null);

    const controlsProps = {
        //if remove this controls show so use for nav
        //https://www.npmjs.com/package/react-full-page?activeTab=explore
        style: {
            display: "none",
        },
    };

    const scrollToSection = (elementRef) => {
        elementRef.current.scrollIntoView({ behavior: "smooth" });
    };

    document.addEventListener(
        "scroll",
        (event) => {
            AOS.init({ once: true });
        },
        { capture: true, passive: true }
    );

    return (
        <Box
            width="100%"
            sx={{
                position: "relative",
                //height: window.screen.availHeight, //too tall is the menu and browser menu
                height: window.innerHeight, //too small when scrolling down ok when scrolling up
                //height: `${(100 * window.innerHeight) / window.screen.availHeight}vh`, ///too small
                //height: "fill-available", //nope

                //height: `${calc(var(--vh, 1vh) * 100)}`,
                overflowY: "scroll",
                scrollSnapType: "y mandatory",
                scrollBehavior: "smooth",
            }}
        >
            <NavToolSmall
                accueil={accueil}
                aboutUs={aboutUs}
                steps={steps}
                whyUs={whyUs}
                contact={contact}
                scrollToSection={scrollToSection}
            />

            <AccueilSmall
                accueil={accueil}
                contact={contact}
                scrollToSection={scrollToSection}
            />

            <AboutUsSmall aboutUs={aboutUs} />

            <StepsLayoutSmall steps={steps} />

            <WhyUsSmall whyUs={whyUs} />

            <ContactSmall contact={contact} />
        </Box>
    );
}

export default HomePageSmall;
