import { Box } from "@mui/material";
import StepsLayout from "../steps/StepsLayout";
import WhyUs from "../whyUs/WhyUs";
import NavTool from "../navigation/NavTool";
import Accueil from "../accueil/Accueil";
import { useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import AboutUs from "../aboutUs/AboutUs";
import Contact from "../contact/Contact";

function HomePage() {
    const accueil = useRef(null);
    const aboutUs = useRef(null);
    const steps = useRef(null);
    const whyUs = useRef(null);
    const contact = useRef(null);

    const scrollToSection = (elementRef) => {
        elementRef.current.scrollIntoView({ behavior: "smooth" });
    };

    document.addEventListener(
        "scroll",
        (event) => {
            AOS.init({ once: true });
        },
        { capture: true, passive: true }
    );
    return (
        <Box
            width="100%"
            sx={{
                position: "relative",
                height: "100vh",
                overflowY: "scroll",
                scrollSnapType: "y mandatory",
                overflowX: "hidden",
            }}
        >
            <NavTool
                accueil={accueil}
                aboutUs={aboutUs}
                steps={steps}
                whyUs={whyUs}
                contact={contact}
                scrollToSection={scrollToSection}
            />
            <Accueil
                accueil={accueil}
                contact={contact}
                scrollToSection={scrollToSection}
            />

            <AboutUs aboutUs={aboutUs} />

            <StepsLayout steps={steps} />

            <WhyUs whyUs={whyUs} />

            <Contact contact={contact} />
        </Box>
    );
}

export default HomePage;
