import { Box, Button, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState, useEffect, useContext } from "react";

import { ContextLangue } from "../context/Context";

function NavToolSmall(props) {
    const [showMenu, setShowMenu] = useState(false);
    const [contextLangue, setContextLangue] = useContext(ContextLangue);

    function LanguageSwitch() {
        if (contextLangue === "fr") {
            setContextLangue("en");
        } else {
            setContextLangue("fr");
        }
    }

    return (
        <Box
            sx={{
                position: "fixed",
                top: showMenu ? 0 : "1rem",
                right: showMenu ? 0 : "1rem",
                zIndex: 500,
            }}
        >
            <Box
                variant="contained"
                sx={{
                    backgroundColor: "rgb(254, 157, 38)",
                    borderColor: "orange",

                    borderRadius: showMenu ? "0" : "100%",
                    width: showMenu ? "100vw" : "4rem",
                    height: showMenu ? "100vh" : "4rem",
                    transition: " all 0.3s ease-in-out",
                }}
                onClick={() => {
                    setShowMenu(!showMenu);
                }}
            >
                {showMenu && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            height: "100%",
                            alignItems: "middle",
                            width: "100%",
                        }}
                    >
                        <CancelIcon
                            sx={{
                                position: "absolute",
                                top: "3vw",
                                right: "3vw",
                                color: "rgb(38,104,191)",

                                width: "15vw",
                                height: "15vw",
                            }}
                        />
                        <Typography
                            onClick={() => {
                                props.scrollToSection(props.accueil);
                            }}
                            sx={{
                                fontFamily: "Tahoma",
                                fontWeight: "bold",
                                fontSize: "7vw",
                                color: "white",
                            }}
                            align="center"
                        >
                            {contextLangue === "fr" ? "Accueil" : "Home"}
                        </Typography>
                        <Typography
                            onClick={() => {
                                props.scrollToSection(props.aboutUs);
                            }}
                            sx={{
                                fontFamily: "Tahoma",
                                fontSize: "7vw",
                                fontWeight: "bold",
                                color: "white",
                            }}
                            align="center"
                        >
                            {contextLangue === "fr" ? "À propos" : "About us"}
                        </Typography>
                        <Typography
                            onClick={() => {
                                props.scrollToSection(props.steps);
                            }}
                            sx={{
                                fontFamily: "Tahoma",
                                fontWeight: "bold",
                                fontSize: "7vw",
                                color: "white",
                            }}
                            align="center"
                        >
                            Étapes
                        </Typography>
                        <Typography
                            onClick={() => {
                                props.scrollToSection(props.whyUs);
                            }}
                            sx={{
                                fontFamily: "Tahoma",
                                fontSize: "7vw",
                                color: "white",
                                fontWeight: "bold",
                            }}
                            align="center"
                        >
                            {contextLangue === "fr"
                                ? "Pourquoi nous choisir"
                                : "Why us"}
                        </Typography>
                        <Typography
                            onClick={() => {
                                props.scrollToSection(props.contact);
                            }}
                            sx={{
                                fontFamily: "Tahoma",
                                fontSize: "7vw",
                                fontWeight: "bold",
                                color: "white",
                            }}
                            align="center"
                        >
                            {contextLangue === "fr"
                                ? "Nous contacter"
                                : "Contact us"}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                position: "absolute",
                                top: "7.5vw",
                                left: "6.5vw",
                            }}
                        >
                            <Typography
                                onClick={LanguageSwitch}
                                sx={{
                                    fontFamily: "Tahoma",
                                    fontSize: "6vw",
                                    fontWeight: "bold",
                                    color: "white",
                                }}
                            >
                                {contextLangue === "fr" ? "EN" : "FR"}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {!showMenu && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        height="100%"
                    >
                        <MenuIcon fontSize="large" sx={{ color: "white" }} />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default NavToolSmall;
