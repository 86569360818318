import { Box, Typography } from "@mui/material";

function StepsItemSmall(props) {
  return (
    <Box
      onClick={() => props.handleDivClick(props.id)}
      sx={{
        zIndex: props.zInd,
        width: "35%",
        height: "35vw",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        bottom: props.selectedDiv === props.id ? "55%" : props.bottomPos,
        right: props.selectedDiv === props.id ? "32.5%" : props.rightPos,
        transition: " all 0.5s ease-in-out",
        transform: props.selectedDiv === props.id ? "scale(2.7)" : null,
        backgroundColor: props.bColor,
        "&:hover": {
          transform: props.selectedDiv === props.id ? null : "scale(1.3)",
          transformOrigin: props.selectedDiv === props.id ? null : "right",
          transition:
            props.selectedDiv === props.id ? null : "0.2s ease-in-out",
          cursor: props.selectedDiv === props.id ? null : "pointer",
          zIndex: 100,
        },
      }}
    >
      {props.selectedDiv === props.id ? (
        <Box width="100%">
          <Typography
            color="white"
            align="center"
            variant="h3"
            sx={{ fontSize: "3vw", fontFamily: "Tahoma" }}
            marginLeft={2}
            marginRight={2}
          >
            {props.title}
          </Typography>
          <Typography
            color="white"
            align="justify"
            sx={{ fontSize: "1.35vw", fontFamily: "Tahoma" }}
            marginLeft={2}
            marginRight={2}
          >
            {props.text}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            position: "relative",

            width: "100%",
          }}
        >
          <Typography
            color="white"
            align={props.id === 1 ? "center" : "right"}
            width="calc(100% - 9%)"
            sx={{
              color: props.numColor,

              fontSize: "3vh",
              fontWeight: "bold",
            }}
          >
            {props.id}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default StepsItemSmall;
