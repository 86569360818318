import { Box, Typography } from "@mui/material";

function WhyUsItemSmall(props) {
  return (
    <Box
      width="100%"
      sx={{
        transition: " all 0.5s ease-in-out",

        flex: props.selectedDiv === props.item ? 10 : 1,
        backgroundColor: props.couleur,
        position: "relative",
        "&:hover": {
          cursor: props.selectedDiv === props.item ? null : "pointer",
        },
      }}
      onClick={() => {
        props.setSelectedDiv(props.item);
      }}
    >
      {props.selectedDiv !== props.item && (
        <Typography
          width="fit-content"
          align="center"
          sx={{
            marginRight: "auto",
            marginLeft: "auto",
            fontFamily: "Tahoma",
          }}
          color="white"
          variant="h6"
        >
          {props.title}
        </Typography>
      )}
      {props.selectedDiv === props.item && (
        <Box
          sx={{
            position: "relative",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            color="white"
            variant="h3"
            align="left"
            width="70%"
            marginBottom={2}
            sx={{ fontWeight: "bold", fontFamily: "Tahoma" }}
          >
            {props.title}
          </Typography>
          <Typography
            color="white"
            variant="h6"
            width="70%"
            sx={{ fontSize: "5vw", fontFamily: "Tahoma" }}
          >
            {props.text}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default WhyUsItemSmall;
