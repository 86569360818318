import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import HomePageSmall from "./components/pages/HomePageSmall";
import { ContextLangue } from "./components/context/Context.js";
import { useState } from "react";

function App() {
  const [contextLangue, setContextLangue] = useState("fr");
  return (
    <ContextLangue.Provider value={[contextLangue, setContextLangue]}>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Routes>
          <Route path="/" element={<HomePageSmall />} />
        </Routes>
      </Box>
    </ContextLangue.Provider>
  );
}

export default App;
