import { Box, Button, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState, useEffect, useContext } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import { ContextLangue } from "../context/Context";

function NavTool(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [contextLangue, setContextLangue] = useContext(ContextLangue);

  function LanguageSwitch() {
    if (contextLangue === "fr") {
      setContextLangue("en");
    } else {
      setContextLangue("fr");
    }
  }
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [showMenu]);
  return (
    <Box
      sx={{
        position: "fixed",
        top: showMenu ? 0 : "3vw",
        left: showMenu ? 0 : "3vw",
        zIndex: 500,
        transition: " all 0.3s ease-in-out",
      }}
    >
      <Button
        variant="contained"
        sx={{
          backgroundColor: "rgb(254, 157, 38)",
          borderColor: "orange",
          "&:hover": { backgroundColor: "rgb(254, 157, 38)" },

          borderRadius: showMenu ? "0" : "50%",
          width: showMenu ? "100vw" : "5vw",
          height: showMenu ? "100vh" : "5vw",
          transition: " all 0.3s ease-in-out",
        }}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        {showMenu && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: "100%",
              alignItems: "flex-start",
              width: "100%",
              paddingLeft: "calc((100vh - 25vw) / 6)",
            }}
          >
            <CancelIcon
              sx={{
                position: "absolute",
                top: "3vw",
                right: "3vw",
                color: "rgb(38,104,191)",
                "&:hover": { color: "rgb(30,90,180)" },
                width: "7vw",
                height: "7vw",
              }}
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="1250"
            />
            <Typography
              onClick={() => {
                props.scrollToSection(props.accueil);
              }}
              sx={{
                fontFamily: "Tahoma",
                fontWeight: "bold",
                fontSize: "5vw",
                color: "white",
                "&:hover": { color: "rgb(38,104,191)" },
              }}
              align="left"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              {contextLangue === "fr" ? "Accueil" : "Home"}
            </Typography>

            <Typography
              onClick={() => {
                props.scrollToSection(props.aboutUs);
              }}
              sx={{
                fontFamily: "Tahoma",
                fontSize: "5vw",
                fontWeight: "bold",
                color: "white",
                "&:hover": { color: "rgb(38,104,191)" },
              }}
              align="left"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="250"
            >
              {contextLangue === "fr" ? "À propos" : "About us"}
            </Typography>
            <Typography
              onClick={() => {
                props.scrollToSection(props.steps);
              }}
              sx={{
                fontFamily: "Tahoma",
                fontWeight: "bold",
                fontSize: "5vw",
                color: "white",
                "&:hover": { color: "rgb(38,104,191)" },
              }}
              align="left"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="500"
            >
              {contextLangue === "fr" ? "Étapes" : "Steps"}
            </Typography>
            <Typography
              onClick={() => {
                props.scrollToSection(props.whyUs);
              }}
              sx={{
                fontFamily: "Tahoma",
                fontSize: "5vw",
                color: "white",
                fontWeight: "bold",
                "&:hover": { color: "rgb(38,104,191)" },
              }}
              align="left"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="750"
            >
              {contextLangue === "fr" ? "Pourquoi nous choisir" : "Why us"}
            </Typography>
            <Typography
              onClick={() => {
                props.scrollToSection(props.contact);
              }}
              sx={{
                fontFamily: "Tahoma",
                fontSize: "5vw",
                fontWeight: "bold",
                color: "white",
                "&:hover": { color: "rgb(38,104,191)" },
              }}
              align="left"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="1000"
            >
              {contextLangue === "fr" ? "Nous contacter" : "Contact us"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                bottom: "3vw",
                right: "6.5vw",
              }}
            >
              <Typography
                onClick={LanguageSwitch}
                sx={{
                  fontFamily: "Tahoma",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  color: "white",
                  "&:hover": { color: "rgb(38,104,191)" },
                }}
              >
                {contextLangue === "fr" ? "EN" : "FR"}
              </Typography>
            </Box>
          </Box>
        )}
        {!showMenu && <MenuIcon fontSize="large" sx={{ color: "white" }} />}
      </Button>
    </Box>
  );
}

export default NavTool;
