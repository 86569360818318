import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import emailjs from "@emailjs/browser";
import { useRef, useState, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "react-reveal/Fade";
import { ContextLangue } from "../context/Context";

function Contact(props) {
    const [contextLangue, setContextLangue] = useContext(ContextLangue);
    const form = useRef();
    const [nameValue, setNameValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [messageValue, setMessageValue] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showSentAlert, setShowSentAlert] = useState(false);

    const handleNameChange = (event) => {
        setNameValue(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmailValue(event.target.value);
    };
    const handleMessageChange = (event) => {
        setMessageValue(event.target.value);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        if (nameValue && emailValue && messageValue) {
            setShowErrorAlert(false);
            setShowSentAlert(true);

            emailjs
                .sendForm(
                    `${process.env.REACT_APP_SERVICE_ID}`,
                    `${process.env.REACT_APP_TEMPLATE_ID}`,
                    form.current,

                    `${process.env.REACT_APP_PUBLIC_KEY}`
                )
                .then(
                    (result) => {
                        console.log(result.text);

                        setNameValue("");
                        setEmailValue("");
                        setMessageValue("");
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        } else {
            setShowSentAlert(false);
            setShowErrorAlert(true);
            console.log("Vous devez remplir tous les champs correctement");
        }
    };

    return (
        <Box
            ref={props.contact}
            height="100%"
            sx={{
                backgroundColor: "rgb(50,50,50)",
                position: "relative",
                scrollSnapAlign: "end",
                scrollSnapStop: "always",
                overflow: "hidden",
            }}
            boxSizing="border-box"
        >
            <Box sx={{ position: "absolute", top: "10vh", left: "10%" }}>
                <Fade left>
                    <div style={{ display: "flex" }}>
                        <Typography
                            variant="h2"
                            sx={{
                                width: "fit-content",
                                fontFamily: "Tahoma",
                                fontWeight: "bold",
                                fontSize: "6vw",
                                background:
                                    "linear-gradient(to right, rgb(38,104,191), rgb(178,200,230))",
                                backgroundClip: "text",
                                color: "transparent",
                            }}
                        >
                            {contextLangue === "fr"
                                ? "ÉCRIVEZ-NOUS"
                                : "WRITE TO US"}
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: "bold",
                                fontFamily: "Tahoma",
                                fontSize: "6vw",
                                color: "orange",
                            }}
                        >
                            !
                        </Typography>
                    </div>
                </Fade>
            </Box>

            <Box
                sx={{
                    zIndex: 25,
                    width: "75%",
                    height: "50vh",
                    position: "absolute",
                    top: "35vh",
                    left: "10%",
                    border: "solid 5px orange",
                }}
            >
                <form ref={form} onSubmit={sendEmail} id="form">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "50%",
                            justifyContent: "space-around",
                            height: "50vh",
                            marginLeft: 2,
                        }}
                    >
                        <TextField
                            id="name"
                            type="text"
                            label={contextLangue === "fr" ? "Nom" : "Name"}
                            name="user_name"
                            value={nameValue}
                            onChange={handleNameChange}
                            autoComplete="off"
                            sx={{
                                "& .MuiInputBase-root": {
                                    color: "white",
                                },
                            }}
                        />
                        <TextField
                            id="email"
                            type="email"
                            label="Email"
                            name="user_email"
                            value={emailValue}
                            onChange={handleEmailChange}
                            sx={{
                                "& :-webkit-autofill": {
                                    WebkitBoxShadow:
                                        "0 0 0 100px rgb(50,50,50) inset",
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiInputBase-root": {
                                    color: "white",
                                },
                            }}
                        />
                        <TextField
                            id="message"
                            type="text"
                            label="Message"
                            multiline
                            maxRows={6}
                            minRows={4}
                            name="message"
                            value={messageValue}
                            onChange={handleMessageChange}
                            autoComplete="off"
                            sx={{
                                "& .MuiInputBase-root": {
                                    color: "white",
                                },
                            }}
                        />
                        <Button type="submit" variant="contained">
                            {contextLangue === "fr" ? "Envoyer" : "Send"}
                        </Button>
                    </Box>
                </form>
                <Box sx={{ position: "absolute", right: 0, display: "flex" }}>
                    <Typography
                        variant="h6"
                        color="white"
                        marginTop={4}
                        marginRight={1}
                        sx={{ fontWeight: 300, fontFamily: "Tahoma" }}
                    >
                        {contextLangue === "fr"
                            ? "Vous pouvez aussi nous écrire directement au:"
                            : "You can also directly write to us at:"}
                    </Typography>
                    <Typography
                        variant="h6"
                        color="white"
                        marginTop={4}
                        onClick={() =>
                            (window.location =
                                "mailto:wigglyweb.designs@gmail.com?subject=Idée de projet")
                        }
                        sx={{
                            "&:hover": { cursor: "pointer" },
                        }}
                    >
                        wigglyweb.designs@gmail.com
                    </Typography>
                </Box>
            </Box>
            <Alert
                severity="error"
                sx={{
                    position: "absolute",
                    top: 15,
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    width: "fit-content",

                    display: showErrorAlert ? "flex" : "none",
                }}
                action={
                    <Button
                        color="inherit"
                        size="fit-content"
                        onClick={() => {
                            setShowErrorAlert(false);
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </Button>
                }
            >
                {contextLangue === "fr"
                    ? "Veuillez remplir chaque champs correctement avant d'envoyer votre message."
                    : "Please fill out every field correctly before sending your message."}
            </Alert>
            <Alert
                severity="success"
                sx={{
                    position: "absolute",
                    top: 15,
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    width: "fit-content",
                    display: showSentAlert ? "flex" : "none",
                }}
                action={
                    <Button
                        color="inherit"
                        size="fit-content"
                        onClick={() => {
                            setShowSentAlert(false);
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </Button>
                }
            >
                {contextLangue === "fr"
                    ? "Votre message a bien été envoyé!"
                    : "Your message was sent successfully"}
            </Alert>
            <Box
                sx={{
                    position: "absolute",
                    top: "100vh",
                    width: "100%",
                    height: "100%",
                }}
            >
                <Fade top>
                    <Box>
                        <Box
                            sx={{
                                position: "absolute",
                                backgroundColor: "rgb(112,150,201)",
                                width: "10vw",
                                height: "45vh",
                                bottom: 0,
                                left: "65vw",
                            }}
                        />
                        <Box
                            sx={{
                                position: "absolute",
                                backgroundColor: "rgb(112,150,201)",
                                width: "10vw",
                                height: "10vw",
                                bottom: "calc(45vh - 10vw/2)",
                                left: "65vw",
                                borderRadius: "100%",
                            }}
                        />
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                position: "absolute",
                                backgroundColor: "rgb(38,104,191)",
                                width: "10vw",
                                height: "75vh",
                                bottom: 0,
                                left: "80vw",
                            }}
                        />
                        <Box
                            sx={{
                                position: "absolute",
                                backgroundColor: "rgb(38,104,191)",
                                width: "10vw",
                                height: "10vw",
                                bottom: "calc(75vh - 10vw/2)",
                                left: "80vw",
                                borderRadius: "100%",
                            }}
                        />
                    </Box>
                </Fade>
            </Box>
        </Box>
    );
}

export default Contact;
